.footer {
  width: 100%; /* Ensure it spans the width of the screen */
  padding: 10px 20px;
  background-color: #f2f2f2; /* Light grey background */
  color: #333; /* Dark grey text */
  text-align: center; /* Center-align the text */
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow to lift the footer */
  font-size: 14px;
  margin-top: 20px; /* Add some space above the footer */
}

.source-code a {
  display: block; /* Each link on its own line */
  color: #333; /* Dark grey text */
  text-decoration: none; /* No underline */
  margin: 2px 0; /* Small margin between the lines */
}

.source-code a:hover {
  color: #007bff; /* Light blue on hover for a bit of interaction */
}
