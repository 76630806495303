.contact {
  padding: 0rem;
}

.contact-container a {
  color: #007bff;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

.contact-container a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.contact-container {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
}

.contact-title {
  background-color: #f7f7f7;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
}
