.home {
  padding: 0rem;
  text-align: center;
}

.home p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 24px;
  max-width: 800px;
  margin: 0.5rem auto;
}

.home-container {
  text-align: center;
}

.home-title {
  margin-top: 0.25rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 1.5rem;
  background-color: #f7f7f7;
}

.profile-picture {
  width: 350px;
  height: auto;
  border-radius: 50%;
  margin: 0rem 0;
}