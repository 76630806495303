.fresenius {
    max-width: 800px;
    margin: 1rem auto;
    padding: 20px;
    background-color: #fff;
    text-align: center;
  }
  
  .fresenius h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }
  
  .fresenius p {
    font-size: 1.25rem;
    margin-bottom: 10px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }
  
  .fresenius ul {
    padding-left: 0;
    list-style: none;
  }
  
  .fresenius li {
    margin-bottom: 10px;
  }
  
  .logo-picture {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 10px;
    margin: 1rem 0;
  }
  