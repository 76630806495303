.about-container {
  text-align: center;
  padding: 1rem;
}

.about-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 1.5rem;
  background-color: #f7f7f7;
  margin-top: 2rem;
}

.about {
  padding: 0rem;
  text-align: center;

}

.about p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 20px;
  max-width: 800px;
  margin: 1rem auto;

}