.smarthand {
  max-width: 800px;
  margin: 1rem auto;
  padding: 20px;
  background-color: #fff;
  /* border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  text-align: center;
}

.smarthand h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.smarthand p {
  font-size: 1.25rem;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.smarthand-picture {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 10px;
  margin: 1rem 0;
}

.schematic1-picture {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
  margin: 1rem 0;
}

.schematic2-picture {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
  margin: 1rem 0;
}